import React from "react";
import { Link } from "gatsby";
import MetaTags from "../components/MetaTags";
import Dbsq from "../assets/images/dbsq.inline.svg";
import styled from "styled-components";

const IndexWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;

  @media (min-width: 700px) {
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
  }

  > svg {
    width: clamp(140px, 17vw, 220px);
  }

  > div {
    align-self: center;
    text-align: center;
    margin-top: 3rem;

    @media (min-width: 700px) {
      text-align: left;
      margin-top: 0;
      margin-left: clamp(2rem, 4vw, 4rem);
    }
  }

  h1,
  h2 {
    font-family: "Muli", sans-serif;
    margin: 0;
  }

  h1 {
    font-size: clamp(3.5rem, 8vw, 6.5rem);
    font-weight: 800;
    line-height: 1;
    letter-spacing: -0.05em;
  }

  h2 {
    font-size: clamp(2.2rem, 5vw, 4rem);
    font-weight: 300;
  }
`;

const HomeNavWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
  font-size: clamp(3rem, 8vw, 7rem);

  @media(min-width: 700px) {
    margin: 5rem 0 3rem;
  }

  a {
    display: inline-block;
    position: relative;
    margin: 0 0.25em;
    color: var(--fill-green);
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    font-size: clamp(2.5rem, 8vw, 5rem);
    text-decoration: none;
    letter-spacing: -0.015em;
    transition: color 0.3s ease;

    @media(min-wdith: 700px) {
      margin: 0 1em;
    }

    &::after {
      content: " ";
      position: absolute;
      right: 0;
      bottom: -0.1em;
      left: 0;
      padding-right: 0;
      border-bottom: solid var(--bright-green);
      border-bottom-width: 0.2em;
      width: 100%;
      z-index: 0;
      clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 0 0);
      transition: clip-path 0.3s ease, padding-right 0.3s ease;
    }

    &:hover {
      color: #46a58d;

      &::after {
        transition: border-bottom-width 0.25s ease, 
        border-bottom-width: 0.33em;
        clip-path: polygon(97.5% 0, 100% 50%, 97.5% 100%, 0 100%, 0 0);
        padding-right: 2.5%;
      }
    }
  }
`;

const IndexPage = () => (
  <div>
    <IndexWrapper>
      <MetaTags title="Home" />
      <Dbsq />
      <div>
        <h1>Daniel Bull</h1>
        <h2>UX Engineer</h2>
      </div>
    </IndexWrapper>
    <HomeNavWrapper>
      <Link to={"/portfolio"}>Portfolio</Link>
      <Link to={"/posts"}>Posts</Link>
    </HomeNavWrapper>
  </div>
);

export default IndexPage;
